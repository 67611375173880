<template>
  <div class="app-container" ref="container">
    <!-- <div style="width:100%;height:100%;position:absolute;padding-top:30px;"> background:rgba(255, 255, 255, 0.5);-->
    <!-- <div style="width:100%;height:100%;position:absolute;" v-bind:style1="{height: mapheigt}">
    <div id="map-container" style="width:100%;height:100%;"/>
    </div> -->

  
    <div style="margin-top:12px;position:absolute;z-index:2000;width:100%;" v-if="canEditAddress">
          
              <el-input  prefix-icon="el-icon-location" :size="size" v-model="form.address" placeholder="站点" clearable 
              style="width: 170px;float:right;margin-right:20px">
              <el-button type="success" slot="append" :size="size" plain class="button" @click="saveaddress">保存</el-button>
              </el-input>

    </div>
    <div style="width:100%;height:100%;position:absolute;">
    <baidu-map :center="{lng:121.67968256587015,lat:31.538427690383923}" :zoom="12" @ready="handler" style="width:100%;height:100%;"></baidu-map>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
//import loadBMap from '../../plugins/loadBMap.js'
export default {
  data() {
    return {
      size: 'medium',//medium,small,mini,
      mapheigt:"500px",
      form: {
        address: '', // 详细地址
        addrPoint: { // 详细地址经纬度
          lng: 0,
          lat: 0
        }
      },
      map: null, // 地图实例
      mk: null, // Marker实例
      locationPoint: null,
      stationList:[],
      markers:[],
      canEditAddress:true,
      mytimers:[],//定时器数组，便于清理
    }
  },
  async mounted() {
    this.initmapheight();
    //await loadBMap('8ktYGStGratzruLRGVenOKpM7gAn39d6') // 加载引入BMap
    //this.initMap()
    window.onresize = () => {
      this.initmapheight();
      };
  },
  methods: {
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
    // 初始化地图
    handler({BMap, map}){
      this.map=map
      this.mytimers.push(setTimeout(() => {
        this.handlernext()
      }, 100))
    },
    handlernext(){

      var point = new BMap.Point(121.67968256587015,31.538427690383923)
      this.map.centerAndZoom(new BMap.Point(121.64705610974767,31.611031028703508), 12)
      this.map.enableScrollWheelZoom(true);
      // 3、设置图像标注并绑定拖拽标注结束后事件
      var mk = new BMap.Marker(point, { enableDragging: false })
      this.map.addOverlay(this.mk)
      // this.mk.addEventListener('dragend', function(e) {
      //   that.getAddrByPoint(e.point)
      // })
      mk.setAnimation(BMAP_ANIMATION_BOUNCE);
      this.map.addControl(new BMap.GeolocationControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT})) //创建一个地图定位控件

      // 4、添加（右上角）平移缩放控件
      this.map.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_LEFT, type: BMAP_NAVIGATION_CONTROL_SMALL }))
      
      this.mytimers.push(setTimeout(() => {
              this.getstation();
            }, 300));
    },

    // 获取站点列表
    getstation() {
      var that = this
      let data = {
        lx: "getstation",
        procext:"yhjj"
      };
      that.$api.post(
        "",
        data,
        (res) => {
          if (res["status"] == "1") {
            that.markers=[];
            that.stationList = res["records"];
            //that.canEditAddress=res["canedit"]==="0" ? false : true;
            let iconImg = new BMap.Icon("http://www.yh59481717.com/Images/map-6.png", new BMap.Size(44, 50)); 
					  that.stationList.forEach((s,index) => {
               let p1=s.m_mapxy.split("|")[0];
               let p2=s.m_mapxy.split("|")[1];
               let point = new BMap.Point(p1,p2);
               let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+s.TypeName+"</div>",{point:new BMap.Point(p1,p2),offset:new BMap.Size(3,-23)});
               label.setStyle({borderColor:"#808080",color:"#333",cursor:"pointer"});
               let marker = new BMap.Marker(point, { enableDragging: false,icon:iconImg });
               marker.setLabel(label);
               //console.log(that.canEditAddress)
               if (that.canEditAddress){
                  marker.addEventListener('dragend', function(e) {
                    that.getAddrByPoint(e.point,index);
                  })
                  marker.addEventListener('click', function(e) {
                    that.EditAddrByPoint(e.point,index);
                  })
               }
               
               that.markers.push(marker);
               that.map.addOverlay(marker);
            })
          }
        },
        (failure) => {}
      );
      
    },
    saveaddress() {
      var that = this
      let savelist=this.stationList.filter(item => {
				return item.ismodi==="1";
			})
      if (savelist.length==0){
        that.$message.warning("记录未变动，无需保存");
        return;
      }
      that.$confirm("真的要保存吗?", "确认操作", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            lx: "savestation",
            procext:"yhjj",
            sqlone: JSON.stringify(savelist),
          };
          that.$api.post("",data,
            (res) => {
              if (res["status"] == "1"){
                that.$message.success(res["message"]);
                that.stationList.forEach((s) => {
                  s.ismodi="0";
                })
              }else
                that.$message.error(res["message"]);
            },
            (failure) => {
              //this.showUserInfo = false;
              that.$message.error(failure);
            }
          );
        })
        .catch(() => {});
    },
    EditAddrByPoint(point,index) {
       var that = this
       
       that.form.address =that.stationList[index].TypeName;
       that.markers[index].enableDragging();//marker.disableDragging(); 
      // var geco = new BMap.Geocoder()
      // geco.getLocation(point, function(res) {
      //   console.log(res)
      //   that.mk.setPosition(point)
      //   that.map.panTo(point)
      //   that.form.address = res.address
      //   that.form.addrPoint = point
      // })
    },
    // 2、拖动时保存地址
    getAddrByPoint(point,index) {
       var that = this
       console.log(index);
       that.form.address =that.stationList[index].TypeName;
       that.stationList[index].m_mapxy=point.lng+'|'+point.lat;
       that.stationList[index].ismodi="1";
      // var geco = new BMap.Geocoder()
      // geco.getLocation(point, function(res) {
      //   console.log(res)
      //   that.mk.setPosition(point)
      //   that.map.panTo(point)
      //   that.form.address = res.address
      //   that.form.addrPoint = point
      // })
    },
    // 8-1、地址搜索
    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function(res) {
          var s = []
          if (local.getStatus() == BMAP_STATUS_SUCCESS) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i))
            }
            cb(s)
          } else {
            cb(s)
          }
        }
      }
      var local = new BMap.LocalSearch(this.map, options)
      local.search(str)
    },
    // 8-2、选择地址
    handleSelect(item) {
      this.form.address = item.address + item.title
      this.form.addrPoint = item.point
      this.map.clearOverlays()
      this.mk = new BMap.Marker(item.point)
      this.map.addOverlay(this.mk)
      this.map.panTo(item.point)
    },
    getLocalPosition(){
      navigator.geolocation.getCurrentPosition(function (position) {
      //得到html5定位结果
      var x = position.coords.longitude;
      var y = position.coords.latitude;
      
      //由于html5定位的结果是国际标准gps，所以from=1，to=5
      //下面的代码并非实际是这样，这里只是提供一个思路
      // BMap.convgps(x, y, 1, 5, function (convRst) {
      //    var point = new BMap.Point(convRst.x, convRst.y);
      //    //这个部分和上面的代码是一样的
      //    var marker = new BMap.Marker(point);
      //    map.addOverlay(marker);
      //    map.panTo(point);
      //   })
     })
    },
    initmapheight(){
      
            //let contheight = this.$refs.container.offsetHeight;
            //let toolheight = this.$refs.toolbar.$el.offsetHeight;
            //let mapcontainer=document.getElementById("map-container");
            //mapcontainer.style.height="500px";
            //this.mapheigt=contheight-toolheight-13 +"px";
            //window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 170;
            //console.log(contheight,toolheight+12)
          //console.log(document.documentElement.clientHeight);

    },
  },
  created() {
    //this.getLocalPosition();
  },
  beforeDestroy() {
    this.myclearTimer();
    this.map=null
  },
}
</script>
<style>

.app-container {
  width: 100%;
  height: 100%;
}
/* .toolbar{
  background:rgba(255, 255, 255, 0.65) !important; 
} */
</style>
<style lang="scss" scoped>
  .autoAddressClass{
    li {
      i.el-icon-search {margin-top:11px;}
      .mgr10 {margin-right: 10px;}
      .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .address {
        line-height: 1;
        font-size: 12px;
        color: #b4b4b4;
        margin-bottom: 5px;
      }
    }
  }
</style>

